<template>
    <div>
      <v-navigation-drawer
        v-model="drawer"
        app
        temporary
        dark
        color="primary"
      >
        <v-list>
          <v-list-item to="/">
            <v-list-item-title>
              Medi Assist
            </v-list-item-title>
            <v-list-item-avatar>
              <img src="@/assets/img/logo-menu.png" alt="Logo" />
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
  
        <v-divider />
  
        <v-list dense>
          <v-list-item
            v-for="menu in itemsMenu"
            :key="menu.id"
            link
            @click="abrirSeccion(menu)"
          >
            <v-list-item-content>
              <v-list-item-title class="customRose--text subtitile-1">{{
                menu.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            href="https://flamingomedia.mx/medi_assist_admin/register.php"
          >
            <v-list-item-icon class="justify-center">
              <v-icon>mdi-account-plus-outline</v-icon>
              <v-img src="menu.logo" class="mr-2" height="24" width="24"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="customRose--text subtitile-1">
                Registrarse
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>          
        </v-list>
      </v-navigation-drawer>
  
      <v-app-bar
        app
        :color="color"
        :flat="flat"
        :class="{ expand: flat }"
      >
        
        <v-toolbar-title>
          <v-img class="mx-1" src="@/assets/img/logo-menu.png" max-width="46" max-height="40" alt="Logo" contain/>
        </v-toolbar-title>

        <!-- <v-toolbar-title v-else class="pt-0">
          <v-img src="@/assets/img/logo600x100.png" alt="Logo" max-width="250" contain/>
        </v-toolbar-title> -->
        <v-spacer />
        <v-spacer />
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          :class="`${textColor}--text`"
          class="mr-4"
          v-if="isXs"
        />
        <div v-else>
          <v-btn 
            text
            dark
            class="white--text"
            to="/"
          >
            <v-icon left>
              mdi-home
            </v-icon>
          </v-btn>
          <v-btn v-for="menu in itemsMenu"
            :key="menu.id" 
            :class="`${textColor}--text`"
            text
            small
            @click="abrirSeccion(menu)"
          >
            {{menu.title}}
          </v-btn>
          <v-btn 
            text
            dark
            class="white--text"
            href="https://mediassist.mx/medi_assist_admin/register.php"
          >
            <v-icon left>
              mdi-account-plus-outline
            </v-icon>
            Registrarse
          </v-btn>
        </div>
      </v-app-bar>
    </div>
  </template>
  
  <style scoped>
  .v-toolbar {
    transition: 0.6s;
  }
  
  .expand {
    height: 80px !important;
    padding-top: 10px;
  }
  </style>
  
  <script>
  import { mapActions, mapState } from "vuex";

  export default {
    props: {
      logoCompleto: Boolean,
      color: String,
      textColor: String,
      flat: Boolean,
    },
    data: () => ({
      drawer: null,
      isXs: false
    }),
    created() {
      this.getOpcionesMenu();
    },
    computed: {
      ...mapState("loader", ["loading"]),
      ...mapState("landing", ["itemsMenu"]),
    },
    methods: {
      ...mapActions({
        getOpcionesMenu: "landing/getOpcionesMenu",
        // seleccionarSector: "config/seleccionarSector",
      }),
      abrirSeccion(opcion){
        // this.seleccionarSector(opcion)
        // setTimeout(() => {
        //   this.$vuetify.goTo("#infoSector");
        // }, 100);
        this.$router.push({ path: `${opcion.to}` });
      },
      onResize() {
        this.isXs = window.innerWidth < 850;
      },
    },
  
    watch: {
      isXs(value) {
        if (!value) {
          if (this.drawer) {
            this.drawer = false;
          }
        }
      },
    },
    mounted() {
      this.onResize();
      window.addEventListener("resize", this.onResize, { passive: true });
    },
  };
  </script>
  