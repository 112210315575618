<template>
  <v-footer
    dark
    padless
    class="secondary"
  >
    <img src="@/assets/img/main_footer_bg2.png" class="floating-image">
    <v-card
      color="secondary"
      class="flex"
      flat
      tile
      
    >
      <v-card-text class="py-2 white--text text-center">
        <p></p>
      </v-card-text>

      <v-card-text class="white--text text-center">
        {{ new Date().getFullYear() }} — Elaborado por <strong> <a href="https://flamingomedia.mx/" class="white--text text-decoration-none" target="_blank"> flamingomedia.mx</a></strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  // Código del componente
};
</script>

<style scoped>
/* Estilos regulares del componente */

.footer-container {
  position: relative;
}

.floating-image {
  position: relative;
  top: 50%;
  left: 20px; /* Ajusta la posición izquierda según tu preferencia */
  transform: translate(-10%, -50%);
  z-index: 1;
  max-width: 200px;
}

.floating-card {
  position: relative;
  margin-right: 20px; /* Agrega un margen derecho para separar el card de la imagen */
  max-width: calc(100% - 100px); /* Establece el ancho máximo del card para evitar que se superponga con la imagen */
}
</style>
